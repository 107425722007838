<p class="mt-5">Hello world</p>
<div class="flex">
  <div class="flex-initial flex align-items-center justify-content-center font-bold">
    <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
    <button
      type="button"
      pButton
      icon="pi pi-chevron-down"
      label="'liveDashboard' | translate' "
      (click)="menu.toggle($event)"
      style="width: auto"
    ></button>
  </div>

  <div class="flex-initial flex align-items-center justify-content-center font-bold">
    <p-tabMenu styleClass="mb-5" [model]="routeItems"></p-tabMenu>
  </div>
</div>

<div class="grid m-3">
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card">
      <h5>Booking Status</h5>
      <app-booking-status-card></app-booking-status-card>
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card">
      <h5>Booking Status</h5>
      <app-booking-status-card></app-booking-status-card>
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card">
      <h5>Booking Status</h5>
      <app-booking-status-card></app-booking-status-card>
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card">
      <h5>Booking Status</h5>
      <app-booking-status-card></app-booking-status-card>
    </div>
  </div>

  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Revenue</span>
          <div class="text-900 font-medium text-xl">$2.100</div>
        </div>
        <div
          class="flex align-items-center justify-content-center bg-orange-100 border-round"
          [ngStyle]="{ width: '2.5rem', height: '2.5rem' }"
        >
          <i class="pi pi-map-marker text-orange-500 text-xl"></i>
        </div>
      </div>
      <span class="text-green-500 font-medium">%52+ </span>
      <span class="text-500">since last week</span>
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Customers</span>
          <div class="text-900 font-medium text-xl">28441</div>
        </div>
        <div
          class="flex align-items-center justify-content-center bg-cyan-100 border-round"
          [ngStyle]="{ width: '2.5rem', height: '2.5rem' }"
        >
          <i class="pi pi-inbox text-cyan-500 text-xl"></i>
        </div>
      </div>
      <span class="text-green-500 font-medium">520 </span>
      <span class="text-500">newly registered</span>
    </div>
  </div>
  <div class="col-12 lg:col-6 xl:col-3">
    <div class="card mb-0">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block text-500 font-medium mb-3">Comments</span>
          <div class="text-900 font-medium text-xl">152 Unread</div>
        </div>
        <div
          class="flex align-items-center justify-content-center bg-purple-100 border-round"
          [ngStyle]="{ width: '2.5rem', height: '2.5rem' }"
        >
          <i class="pi pi-comment text-purple-500 text-xl"></i>
        </div>
      </div>
      <span class="text-green-500 font-medium">85 </span>
      <span class="text-500">responded</span>
    </div>
  </div>

  <div class="col-12 xl:col-3">
    <div class="card">
      <div class="flex justify-content-between align-items-center mb-5">
        <h5>Passenger by Types</h5>
        <div>
          <button
            pButton
            type="button"
            icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-text p-button-plain"
            (click)="menu.toggle($event)"
          ></button>
          <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>
      </div>
      <ul class="list-none p-0 m-0">
        @for (item of passengerByTypes; track item.label) {
          <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.label }}</span>
            </div>
            <div class="mt-2 md:mt-0 flex align-items-center">
              <span class="ml-3 font-medium">{{ item.count }}</span>
            </div>
          </li>
        }
      </ul>
    </div>
  </div>

  <div class="col-12 xl:col-3">
    <div class="card">
      <div class="flex justify-content-between align-items-center mb-5">
        <h5>Passenger by Types</h5>
        <div>
          <button
            pButton
            type="button"
            icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-text p-button-plain"
            (click)="menu.toggle($event)"
          ></button>
          <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </div>
      </div>
      <ul class="list-none p-0 m-0">
        @for (item of passengerByTypes; track item.label) {
          <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
            <div>
              <span class="text-900 font-medium mr-2 mb-1 md:mb-0">{{ item.label }}</span>
            </div>
            <div class="mt-2 md:mt-0 flex align-items-center">
              <span class="ml-3 font-medium">{{ item.count }}</span>
            </div>
          </li>
        }
      </ul>
    </div>
  </div>

  <div class="col-12 xl:col-6">
    <div class="col-12 md:col-3">
      <div class="card">
        <h5>Upcoming Journey</h5>
        <p-timeline [value]="events1">
          <ng-template pTemplate="marker" let-event>
            <span
              class="flex z-1 w-2rem h-2rem align-items-center justify-content-center text-white border-circle shadow-2"
              [style.backgroundColor]="event.color"
            >
              <i [ngClass]="event.icon"></i>
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            {{ event.date }}
          </ng-template>
        </p-timeline>
      </div>
    </div>
  </div>
</div>
