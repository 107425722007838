import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { LoaderService } from './../components/loader/loader.service';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import sha256 from 'crypto-js/sha256';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
    'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
    Accept: AppSettings.HEADER_CONTENT_TYPE
  })
};

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  httpHandleError: HandleError;
  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler, private router: Router,
    private storageService: StorageService, private loaderService: LoaderService) {
    this.httpHandleError = httpErrorHandler.createHandleError();
  }

  private prependApiUrl(url: string): string {
    return AppSettings.BASE_URL + url;
  }

  get(identifier: string, url: string, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithoutBody('get', identifier, url));
  }

  post(identifier: string, url: string, body: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('post', identifier, url, body));
  }

  put(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.callerWithBody('put', identifier, url, body));
  }

  delete(identifier: string, url: string, loader?: string): Observable<{}> {
    return this.handleHttpSuccess(this.callerWithoutBody('delete', identifier, url));
  }

  imagePost(identifier: string, url: string, body?: any, loader?: string): Observable<{}> {
    this.showLoader(loader);
    return this.handleHttpSuccess(this.imagePostRequest('post', identifier, url, body));
  }


  callerWithoutBody(method: string, identifier: string, url: string): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, method, url, ''), withCredentials: true };
    const that = this;
    if (method === 'get') {
      return this.httpClient.get(this.prependApiUrl(url), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'delete') {
      return this.httpClient.delete(this.prependApiUrl(url), head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }
  callerWithBody(method: string, identifier: string, url: string, body?: any): Observable<{}> {
    if (identifier !== 'sign-out') {
      const call = (AppSettings.IS_IDLE_TIME) ? this.checkIdleTime() : '';
    }
    const that = this;
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, method, url, body), withCredentials: true };

    if (method === 'put') {
      return this.httpClient.put(this.prependApiUrl(url), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    } else if (method === 'post') {
      return this.httpClient.post(this.prependApiUrl(url), body, head).pipe(
        catchError(this.httpHandleError(identifier, []))
      ).pipe(
        map((r: Response) => {
          that.hideLoader();
          return r;
        })
      );
    }
  }

  image(identifier: string, url: string, fileName: string, loader?: string) {
    // responseType: ResponseContentType.Blob
    this.showLoader(loader);
    const timestamp = new Date().getTime();
    const head = { headers: this.getHttpClientHeaders(timestamp, 'GET', url, '') };
    const res = this.httpClient.get(url, head).pipe(
      catchError(this.httpHandleError(identifier, []))
    );
    this.downloadFile(res, this.getContentType(fileName), fileName);
  }

  getHeaderOfExportPost(timestamp, method, url, body, identifier): HttpHeaders {
    return new HttpHeaders({
      'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
      'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
      'Content-Type': AppSettings.HEADER_UPLOAD_CONTENT_TYPE,
      'Accept': AppSettings.HEADER_CONTENT_TYPE,
      'x-client-req-id': uuidv4(),
      'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
      'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), body, 'NA'),
      'X-AUTHORIZATION-TIMESTAMP': timestamp
    });
  }

  imagePostRequest(method, identifier: string, url: any, body: any) {
    const timestamp = new Date().getTime();
    const that = this;
    const head: any = {
      headers: this.getHeaderOfExportPost(timestamp, 'POST', url, body, identifier),
      withCredentials: true, responseType: 'blob',
    };

    return this.httpClient.post(this.prependApiUrl(url), head).pipe(
      catchError(this.httpHandleError(identifier, []))
    ).pipe(
      map((r: Response) => {
        that.hideLoader();
        return r;
      })
    );

  }

  private getHttpClientHeaders(timestamp, method, url, body): HttpHeaders {
    if (this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== undefined
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY) !== null
      && this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY).length > 0) {
      return new HttpHeaders({
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        Accept: AppSettings.HEADER_CONTENT_TYPE,
        // 'X-SESSION-KEY': this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY)
      });
    }
    if (AppSettings.magneticHolder()) {
      return new HttpHeaders({
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
        'x-client-req-id': uuidv4(),
        'X-CLIENT-TYPE': AppSettings.HEADER_WEB,
        'X-AUTH': this.onCreateHMACEncryption(timestamp, method.toUpperCase(), body, 'NA'),
        'X-AUTHORIZATION-TIMESTAMP': timestamp
      });
    } else {
      return new HttpHeaders({
        'X-COUNTRY-CODE': AppSettings.HEADER_COUNTRY_CODE,
        'Accept-Language': AppSettings.HEADER_ACCEPT_LANGUAGE,
        'Content-Type': AppSettings.HEADER_CONTENT_TYPE,
        'Accept': AppSettings.HEADER_CONTENT_TYPE,
      });
    }
  }

  private handleHttpSuccess(res: Observable<{}>): Observable<{}> {
    return res;
  }

  downloadFile(data: any, contentType: string, fileName: string) {
    const blob = new Blob([data.blob()], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    this.hideLoader();
    // setTimeout(link.remove(), 1000);
  }

  private getContentType(fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    switch (extension) {
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'bmp':
        return 'image/x-ms-bmp';
      case 'pdf':
        return 'application/pdf';
      case 'xls':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return '';
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(loader?: string): void {
    if (loader !== undefined && loader !== null && 'none' !== loader.toLowerCase()) {
      this.loaderService.show(loader);
    }
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }


  checkIdleTime() {
    const idleTime = moment().add((AppSettings.IDLE_TIME), 'm').valueOf();
    this.storageService.setItem('interval', idleTime);
  }


  onCreateHMACEncryption(timestamp, method, body, url): any {
    const bodyHash = this.getBodyContentInSha256Base64(body);
    const payload = this.generatePayloadString(
      AppSettings.HEADER_WEB,
      timestamp,
      method,
      url,
      bodyHash
    );
    return this.generateHMAC256Signature(payload, AppSettings.magneticHolder());
  }


  getBodyContentInSha256Base64(body) {
    let bodyHash = "";
    if (body) {
      bodyHash = sha256(body).toString(Base64);
    }
    return bodyHash;
  }

  generatePayloadString(client, time, method, path, bodySha2556base64) {
    const payload = client.concat(
      ";",
      time,
      ";",
      method,
      ";",
      path,
      ";",
      bodySha2556base64
    );
    return payload;
  }

  generateHMAC256Signature(payload, secretKey) {
    const hash = HmacSHA256(payload, secretKey);
    const hashInBase64 = Base64.stringify(hash);
    const authHeaderValue = AppSettings.HMAC_ENC_SIGNATURE + hashInBase64;
    return authHeaderValue;
  }
}
