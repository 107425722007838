import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { PanelMenuModule } from "primeng/panelmenu";
import { Subscription } from "rxjs";
import { LayoutService } from "../../../../app.layout.service";
import { AppIcons } from "../../app.icons";
import { CommonBindingDataService } from "../../services/common-binding-data.service";
import { AppSettings } from "./../../app.settings";
import { SidebarService } from "./../../services/sidebar.service";
import { StorageService } from "./../../services/storage.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  standalone: true,
  imports: [PanelMenuModule],
})
export class SidebarComponent implements OnInit {
  subscription: Subscription;
  user;

  items: MenuItem[] = [
    {
      label: this.commonService.getLabel("sidebar_lbl_dashboard"),
      icon: AppIcons.Home,
      routerLink: ["dashboard"],
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_tier"),
      icon: AppIcons.book,
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_reward_rules"),
      icon: AppIcons.reward,
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_customers"),
      icon: AppIcons.customers,
      routerLink: ["customers"],
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_configaration"),
      icon: AppIcons.configration,
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_nudge"),
      icon: AppIcons.bell,
    },
    {
      label: this.commonService.getLabel("sidebar_lbl_user_guide"),
      icon: AppIcons.guide,
    },
  ];

  constructor(
    private storageService: StorageService,
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {}

  initSidebarItems() {
    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.subscription = this.sidebarService.sideState.subscribe((state: any) => {
      this.items = state;
      this.items = this.removeDuplicates(this.items, "label");
    });
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.manageActiveStatus();
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);
  }

  private manageActiveStatus() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (item.routerLink.length >= 1 && document.location.pathname === item.routerLink[0]) {
          item.expanded = true;
        }
      }
    }
  }

  getMenu() {
    return this.sidebarService.adminSideBar;
  }
}
