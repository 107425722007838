<app-loader></app-loader>
<div>
  <p-toast key="tst"></p-toast>
  <div class="sidebar" (mouseenter)="isSidebarFull=true" (mouseleave)="isSidebarFull=false"
    [ngClass]="isSidebarFull? 'full-width': 'shrink-width' ">
    <app-sidebar></app-sidebar>
  </div>

  <div class="main-panel" [ngClass]="isSidebarFull? 'shrink-width' : 'full-width'">
    <app-header class="top-bar p-grid p-col-nogutter"></app-header>
    <div class="main-container">
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
      

    </div>
  </div>
</div>