import { Injectable } from '@angular/core';
import { RestApiService } from '../services/rest-api.service';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable({
  providedIn: 'root'
})
export class UserCommonService {

  constructor(private restApiService: RestApiService) { }
  signOut(): Observable<any> {
    return this.restApiService.delete('Api to logged out the user', `${AppSettings.TENANT_CODE}/api/v1/sessions`,'page-center');
  }


  signIn(data): Observable<any> {
    return this.restApiService.post('SignIn', `${AppSettings.TENANT_CODE}/api/v1/sessions`, data, 'page-center');
  }

  // signIn(d, data): Observable<any> {
  //   // return this.restApiService.post(d, '/login', data, 'page-center');
  //   return this.restApiService.post(d, '/users/session', data, 'page-center');
  // }

  // passwordChange(data): Observable<any> {
  //   return this.restApiService.put('/secure/users/profile/password', data, 'page-center');
  // }



  setPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/set-password', data, 'page-center');
  }

  getUserProfile(): Observable<any> {
    return this.restApiService.get('profile', '/secure/users/profile', 'page-center');
  }

  forgotPassword(data): Observable<any> {
    return this.restApiService.post('password', '/users/forgot-password', data, 'page-center');
  }

}

