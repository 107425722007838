<div class="flex justify-content-end button-direction">
  <p-button label="New Tier" icon="pi pi-plus" />
</div>
<div class="card">
  <p-menubar [model]="items" class="menu-start">
    <ng-template pTemplate="start">
      <div class="flex search-box">
        <input type="text" pInputText placeholder="Search" />
      </div>
    </ng-template>
  </p-menubar>
</div>
<app-mi-grid-v2
  [url]="url"
  [columnDefs]="columns"
  [actionButtonsDefs]="buttons"
  rowSelection="multiple"
  divClass="mi-grid"
></app-mi-grid-v2>

<div class="flex align-items-center justify-content-end">
  <span class="mx-1 text-color">Items per page: </span>
  <p-dropdown [options]="options" optionLabel="label" optionValue="value" [(ngModel)]="rows2" (ngModelChange)="first2 = 0" />
  <p-paginator
    [first]="first2"
    [rows]="rows2"
    [totalRecords]="120"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
    [showPageLinks]="false"
    [showFirstLastIcon]="false"
  ></p-paginator>
</div>
