import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MiTranslateLoaderService implements TranslateLoader {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'en':
        return of({
          Username: 'Email',
          Password: 'Password',
          'Username is should be greater than x': 'Username is should be greater than {{minval}}',
          forgot_password: 'Forgot password',

          grid_title: 'TITLE',
          grid_trial_start_date: 'START DATE',
          grid_trail_end_date: 'END DATE',
          grid_patients: 'PATIENTS',
          lbl_no_records_found: 'No records found',

          header_lbl_dashboard: 'Dashboard',
          header_lbl_dashboard_info: 'Welcome to',
          header_lbl_blitzo: 'Blitzo-The Reward Point System',
          label_email_required: 'Email is Required',
          label_email_short: 'Email is too short',
          label_email_long: 'Email is too long',
          label_password_required: 'Password is Required',
          label_password_short: 'Password is too short',
          label_password_long: 'Password is too long',
          label_minimum_chars: 'Email is Required',
          label_min_length: 'Minimum length error',
          label_max_length: 'Maximum length excedded',
          sidebar_lbl_dashboard: 'Dashboard',
          sidebar_lbl_tier: 'tier',
          sidebar_lbl_reward_rules: 'Reward Rules',
          sidebar_lbl_customers: 'Customers',
          sidebar_lbl_configaration: 'Configuration',
          sidebar_lbl_nudge: 'Nudge',
          sidebar_lbl_user_guide: 'User Guide',
          sidebar_lbl_vehicles: 'Vehicles',
          sidebar_lbl_drivers: 'Drivers',
          sidebar_lbl_dispatch: 'Dispatch',
          sidebar_lbl_passenger: 'Passenger',
          sidebar_lbl_bookings: 'Bookings',
          sidebar_lbl_service: 'Service',
          sidebar_lbl_reminder: 'Reminder',

          header_logout_confirmation: 'Logout Confirmation',
          lbl_enter_password: 'Password',
          lbl_message: 'Are you sure you want to',
          lbl_user: 'this user?',
          msg_logout:
            'Are you sure that you want to perform this action? \n Logging out from the admin panel will end your current session. Ensure all tasks and configurations are saved. You will need to re-enter your credentials to access the admin functions again.',
          lbl_confirm_idle_session: `Session has been idle over its time limit,
      It will be disconnected in 2 minutes. Press any key now to continue session.`,

          manage_column_header: 'Manage Columns',
          manage_column_sub_header:
            'Select which columns are visible for you. To change the column order, drag and drop the visible fields.',
          manage_column_available_columns: 'Available Columns',
          manage_column_visible_columns: 'Visible Columns',
          manage_column_restore_default: 'Restore defaults',
          manage_column_apply_changes: 'Apply Changes',
          cancel: 'Cancel',
          label_save: 'Save',
          lbl_name: 'Name',
          lbl_email: 'Email',
          lbl_VIP: 'VIP',
          lbl_user_role: 'User Role',
          lbl_total_points: 'Total Points',
          lbl_used_points: 'Used Points',
          lbl_username: 'Username',
          lbl_password: 'Password',
          label_old_email: 'label_old_email',
          label_enter_new_password: 'Enter new Password',
          label_enter_confirm_password: 'Confirm password',
          lbl_new_password: 'New Password',
          label_old_password: 'Old Password',
          label_enter_password: 'Enter password',
          label_enter_otp: 'Enter OTP',
          label_confirm_password: 'Confirm password',
          label_enter_email: 'Enter email',
          lbl_confirm_password: 'Confirm Password',
          lbl_emaiId: 'Enter email',
          lbl_change_email_address: 'Change Email Address',
          lbl_old_email: 'Old Email',
          lbl_enter_otp: 'Enter OTP',
          lbl_forget_password: 'Forgot Password',
          lbl_remember_me: 'Remember me',
          lbl_sign_in: 'Sign In',
          lbl_emailId: 'Email',
          lbl_all_right_reserved: '© logo 2024. All rights reserved',
          lbl_phone_number: 'Phone Number',
          lbl_user_status: 'User Status',
          lbl_new_user: 'New User',
          lbl_create_new: 'Create New',
          lbl_role_name: 'Role Name',
          lbl_role_code: 'Role Code',
          lbl_created_by: 'Created By',
          lbl_created_on: 'Created On',
          lbl_modified_by: 'Modified By',
          lbl_modified_on: 'Modified On',
          lbl_f_name: 'First Name',
          lbl_l_name: 'Last Name',
          lbl_country_code: 'Country Code',
          lbl_user_details: 'User Details',
          search_by_body_type: 'Search by Body Type',
          select_body_type: 'Select Body Type',
          filters_lbl: 'Filters',
          items_per_page: 'Items Per Page',
          lbl_details: 'Details',
          lbl_all: 'All',
          lbl_assigned: 'Assigned',
          lbl_unassigned: 'Unassigned',
          lbl_deleted: 'Deleted',
          lbl_search: 'Search',
          lbl_action: 'Action',
          lbl_actions: 'Actions',
          lbl_restore_defaults: 'Restore defaults',
          lbl_apply: 'Apply',
          lbl_clear_all: 'Clear all',
          clear_selection: 'Clear Selection',
          lbl_tier: 'Tiers',
          lbl_components: 'components',
          liveDashboard: 'Live Dashboard',
          lbl_filters: 'Filters',
          lbl_blocks: 'blocks',
          lbl_kits: 'kits',
          lbl_templates: 'templates',
          lbl_apollo: 'Apollo',
          lbl_points: 'Points',
          lbl_date: 'Date',

          forgot: {
            forgot_password: 'Forgot Password',
            forgot_password_below_text: 'Please, enter your email address so we can send you a link for password reset',
            lbl_new_password: 'Enter new password',
            lbl_confirm_password: 'Enter confirm password',
            lbl_enter_email: 'Enter email',
            lbl_send_otp: 'Send OTP',
            lbl_email: 'Email',
            lbl_enter_otp: 'Enter OTP',
            new_password: 'New Password',
            confirm_password: 'Confirm Password',
            lbl_copy_right: '© logo 2024. All rights reserved',
            label_email_required: 'Email is Required',
            label_new_password_required: 'New password is Required',
            label_confirm_password_required: 'Confirm password is Required',
          },
        });
    }
  }
}
