<div class="header ml-2">
  <h4 translate class="ml-3 mt-2">sidebar_lbl_dashboard</h4>
  <h6 class="ml-3 header-big" translate>header_lbl_dashboard_info <b translate="">header_lbl_blitzo</b></h6>

  <div class="menubar-top">
    <p-menubar [model]="items">
      <ng-template pTemplate="item" let-item let-root="root">
        <a pRipple class="flex align-items-center p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="ml-2">{{ item.label }}</span>
          <p-badge *ngIf="item.badge" [ngClass]="{ 'ml-auto': !root, 'ml-2': root }" [value]="item.badge" />
          <span *ngIf="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{
            item.shortcut
          }}</span>
          <i *ngIf="item.items" [ngClass]="['pi', root ? 'pi-angle-down ml-2' : 'pi-angle-right ml-auto']"></i>
        </a>
      </ng-template>
    </p-menubar>
  </div>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
</div>
