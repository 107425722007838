import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';

import { UiService } from './../../services/ui.service';
import { GridService } from './grid.service';
import { GridModel } from './grid-model';
import { LoaderService } from '../loader/loader.service';
import { LazyLoadEvent } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-mi-grid-v2',
  providers: [GridService],
  templateUrl: './mi-grid.component.html',
  standalone: true,
  imports: [FormsModule, TableModule]
})
export class MiGridComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Input() url: any = '';
  @Input() searchBar: string;
  @Input() exportButton: string;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 11;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any =
    '<span class="ag-overlay-loading-center" "style=background:red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: GridModel;
  totalRecords = 0;
  sortF = '';
  sortO = '';
  offset = 0;
  setRowLength = 10;
  searchText = '';
  selectedRecords: any = [];
  constructor(
    private elm: ElementRef,
    private loaderService: LoaderService,
    private uiService: UiService,
    private gridService: GridService
  ) { }

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent.subscribe(
      (state: string) => {
        this.refreshList();
      }
    );
    // console.log(this.columnDefs);
  }

  onResize(event: any) {
    this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    const that = this;
    let params =
      'search=' +
      this.searchText +
      '&offset=' +
      this.offset +
      '&limit=' +
      this.setRowLength;

    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = this.url;
    if (this.url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    this.gridService.loadGridData(buildUrl).subscribe(result => {
      this.loaderService.hide();
      const model = <GridModel>result;
      if (result !== undefined) {
        this.totalRecords = model.pagination.totalRecords;
        that.datasource = [];
        model.records.forEach(e => {
          that.columnDefs.forEach(c => {
            if (c && c.cellRenderer && !c.htmlRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            }
            if (c && c.htmlRenderer) {
              e[c.field] = c.htmlRenderer(e);
            }
          });

          that.datasource.push(e);
        });
      }
    });
  }

  public onQuickFilterChanged($event) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.refreshList();
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    // console.log(event);
    // console.log(this.paginationStartPage);
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    // console.log(event);
    // console.log(this.selectedRecords);
    this.rowClickedEvent.emit(this.selectedRecords);
  }

  onRowUnselect(event) {
    // console.log(event);
    // console.log(this.selectedRecords);
  }

  actionBtnClick(data: any, action: string) {
    // console.log(data + ' action:' + action);
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.fetchAndLoadData();
  }
}
