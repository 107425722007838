import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import * as moment from 'moment';
import { StorageService } from './../../shared/services/storage.service';
import { AppSettings } from './../../shared/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';
@Injectable()
export class CommonBindingDataService {
  data = {
    "limit": 20,
    "offset": 0
  }

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) { }

  getAttributeLabels(data) {
    const attributeLabels = [];
    for (const labelsObj of data.attributeLabels) {
      attributeLabels[labelsObj.labelKey] = labelsObj.labelValue
    }
    for (const Attributes of data.relatedAttributes) {
      for (const labelsObj of Attributes.attributeLabels) {
        attributeLabels[labelsObj.labelKey] = labelsObj.labelValue
      }
    }
    return attributeLabels;
  }

  getColumns(data) {
    // const orgData = this.getOrganizedAttribute(data);
    const orgData = data;
    const fields = [];
    for (const tab of orgData.tabs) {
      for (const group of tab.groups) {
        fields.push(...group.fields)
      }
    }
    // const tableColumns = _.sortBy(_.remove(fields, (n) => {
    //   return n.tableColumnIndex < 0;
    // }), [(o) => { return o.tableColumnIndex; }]);

    const cols = _.sortBy(fields,
      [function(o) { return o.attributeIndex; }]);

    return cols;

  }

  getFilterList(data) {
    const filterList = _.remove(data, (n) => {
      return n.filterIndex > 0;
    });

    return filterList;
  }

  getOrgColumnData(data) {
    for (const tab of data.tabs) {
      for (const group of tab.groups) {
        if (group.entityRelationshipConfigId) {
          const configId = group.entityRelationshipConfigId
          for (const relation of data.relations) {
            if (configId === relation.entityRelationshipConfigId) {
              group.relation = relation;
              for (const attribute of data.relatedAttributes) {
                if (relation.otherEntityCode === attribute.entityCode) {
                  group.fields = attribute.tabs[0].groups[0].fields;
                }
              }
            }
          }
        } else {
          group.relation = { relation: 'oneToOne', }
        }
      }
    }
    return data;

  }

  getOrganizedAttribute(data) {
    for (const tab of data.tabs) {
      for (const group of tab.groups) {
        if (group.entityRelationshipConfigId) {
          const configId = group.entityRelationshipConfigId
          for (const relation of data.relations) {
            if (configId === relation.entityRelationshipConfigId) {
              group.relation = relation;
              for (const attribute of data.relatedAttributes) {
                if (relation.otherEntityCode === attribute.entityCode) {
                  group.fields = attribute.tabs[0].groups[0].fields;
                }
              }
            }
          }
        } else {
          group.relation = { relation: 'oneToOne', }
        }
      }
    }
    return data;

  }

  getCountryDetails(): Observable<any> {
    return this.restApiService.post('COUNTRY SEARCH', `${AppSettings.TENANT_CODE}/api/v1/countries/search`, this.data, 'page-center');
  }

  getLanguageDetails(): Observable<any> {
    return this.restApiService.post('GET LANGUAGE DETAILS', `${AppSettings.TENANT_CODE}/api/v1/languages/search`, this.data, 'page-center');
  }

  uploadFile(file): Observable<any> {
    return this.restApiService.imagePostRequest('FILE UPLOAD', `${AppSettings.TENANT_CODE}/api/v1/files/${AppSettings.DOCUMENTS_TYPE.PROFILE}/upload`, file, 'page-center');
  }

  getLabelValue(checkLabelKey, attributeLabels, defaultValue) {
    return attributeLabels[checkLabelKey] || defaultValue;
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }


  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }


  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  telFormat(value) {
    if (value) {
      return value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
    } else {
      return '';
    }
  }

  getState(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.name,
          value: item.stateId
        });
      }
    }
    return states;
  }


  getAgeGroup(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.ageGroupLabel,
          value: item.ageGroupId
        });
      }
    }
    return states;
  }

  getCities(response) {
    const cities = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        cities.push({
          label: item.name,
          value: item.cityId
        });
      }
    }
    return cities;
  }

  getDosageDays(response) {
    const medicationDosageDays = [];
    if (response) {
      for (const item of response) {
        medicationDosageDays.push({
          label: item.medicationDosageDayLabel,
          value: item.medicationDosageDayId
        });
      }
    }
    return medicationDosageDays;
  }

  getDosageUnits(response) {
    const medicationDosageUnits = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        medicationDosageUnits.push({
          label: item.medicationDosageUnitText,
          value: item.medicationDosageUnitId
        });
      }
    }
    return medicationDosageUnits;
  }

  getAnswerType(response) {
    const answerType = [];
    if (response) {
      for (const item of response) {
        answerType.push({
          label: item.answerTypeLabel,
          value: item.answerTypeId
        });
      }
    }
    return answerType;
  }

  getMedicatinDosageSkipReasons(response) {
    const medicationSkipReasons = [];
    if (response) {
      for (const item of response) {
        medicationSkipReasons.push({
          label: item.dosageSkipReason,
          value: item.dosageSkipReasonId
        });
      }
    }
    return medicationSkipReasons;
  }

  getMedicationFreqeuncies(response) {
    const medicationFrequencies = [{
      label: this.getLabel('lbl_please_select'),
      value: {}
    }];
    if (response) {
      for (const item of response) {
        medicationFrequencies.push({
          label: item.medicationDosageFrequencyLabel,
          value: {
            medicationDosageFrequencyId: item.medicationDosageFrequencyId,
            defaultTimes: item.defaultTimes,
          }
        });
      }
    }
    return medicationFrequencies;
  }

  msToHM(milliseconds: number) {
    // 1- Convert to seconds:
    let seconds = milliseconds / 1000;
    // 2- Extract hours:
    const hours = seconds / 3600;  // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = seconds / 60; // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return hours + ':' + minutes;
  }

  timeToMilliseconds(mins, hh) {
    return (mins * 60000) + (hh * 60 * 60000);
  }

  gmtTOHHMM(time) {
    return (moment(parseInt(time, 10)).utc().format('HH:mm'));
  }

  duplicatesFromArray(arr) {
    const sorted_arr = arr.slice().sort();
    const results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  close(event: any) {
    const divsToHide = document.getElementsByClassName('grid-menu');
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i]['style'].display = 'none';
    }
  }

  weekOfTheDay(start) {
    return moment(start).startOf('week').format('MMM D') + '-' + moment(start).endOf('week').format('MMM D');
  }


  millisecondsToTime(scheduledTime) {
    scheduledTime = parseInt(scheduledTime, 10);
    const hh = parseInt(moment(scheduledTime).utc().format('HH'), 10);
    const mm = parseInt(moment(scheduledTime).utc().format('mm'), 10);
    return hh + ':' + mm;
  }


  setWeeklyDays(startDate: number, endDate: number) {
    const tz = moment().utcOffset();
    const weekly = [];
    weekly.push({
      label: this.getLabel('lbl_trial_period'),
      value: {
        start: startDate,
        end: endDate
      }
    }, {
      label: this.weekOfTheDay(startDate),
      value: {
        start: moment(startDate).utc().startOf('week').valueOf(),
        end: moment(startDate).utc().endOf('week').valueOf()
      }
    });

    let start = moment(startDate).utc().startOf('week').add(7, 'd').valueOf();
    while (start < endDate) {
      weekly.push({
        label: this.weekOfTheDay(start),
        value: {
          start: moment(start).utc().startOf('week').valueOf(),
          end: moment(start).utc().endOf('week').valueOf()
        }
      });

      start = moment(start).utc().startOf('week').add(7, 'd').valueOf();
    }
    return weekly;
  }


  getUTCDateWithOutLocalTime(time) {
    const tz = moment().utcOffset();
    time = time - (tz * 60000);
    return moment(time).format('ll');
  }
}
