import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { AppRoutingModule } from './app/app-routing.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { PanelModule } from 'primeng/panel';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { CommonBindingDataService } from './app/modules/shared/services/common-binding-data.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { HttpErrorHandler } from './app/modules/shared/services/http-error-handler.service';
import { MiTranslateLoaderService } from './app/modules/shared/services/mi-translate-loader.service';
import { MiMissingTranslationHandlerService } from './app/modules/shared/services/mi-missing-translation-handler.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, PanelModule, EditorModule, DialogModule, MultiSelectModule, AppRoutingModule, FieldsetModule, OverlayPanelModule, CalendarModule, FileUploadModule, ConfirmDialogModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: MiTranslateLoaderService
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MiMissingTranslationHandlerService
        }
      })
    ),
    HttpErrorHandler, MessageService, CommonBindingDataService, ConfirmationService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => console.log(err));
