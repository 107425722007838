import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomersComponent } from "./modules/customers/components/customers.component";
import { DashboardComponent } from "./modules/dashboard/components/dashboard/dashboard.component";
import { MiIconsComponent } from "./modules/dashboard/components/mi-icons/mi-icons.component";
import { LayoutComponent } from "./modules/shared/components/layout/layout.component";

export const routes: Routes = [
  { path: "", redirectTo: "auth/login", pathMatch: "full" },

  {
    path: "app",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "customers",
        component: CustomersComponent,
        children: [
          {
            path: "",
            component: CustomersComponent,
          },
          {
            path: "icons",
            component: MiIconsComponent,
          },
        ],
      },
    ],
  },

  {
    path: "auth/login",
    loadComponent: () => import("./pages/auth/login/login.component").then((m) => m.LoginComponent),
  },
  {
    path: "auth/forgot-password",
    loadComponent: () => import("./pages/auth/forgot-password/forgot-password.component").then((m) => m.ForgotPasswordComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
