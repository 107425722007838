import { Component } from '@angular/core';

@Component({
  selector: 'app-booking-status-card',
  templateUrl: './booking-status-card.component.html',
  styleUrls: ['./booking-status-card.component.scss'],
  standalone: true
})
export class BookingStatusCardComponent {

}
