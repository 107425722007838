import { Component } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from './modules/shared/components/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LoaderComponent]
})
export class AppComponent {
  title = 'app';
  msgs: Message[] = [];
  constructor(private messageService: MessageService,translate: TranslateService) { 


      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('en');
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use('en');
    
  }
}
