import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, Message, MessageService } from 'primeng/api';
import { Subscription, timer } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { AppSettings } from './../../app.settings';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from './../../services/storage.service';
import { UserCommonService } from './../../services/user-common.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { RippleModule } from 'primeng/ripple';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [ConfirmDialogModule, HeaderComponent, TranslateModule, BadgeModule, AvatarModule, InputTextModule, CommonModule, RippleModule, MenubarModule]
})
export class HeaderComponent implements OnInit {
  items: MenuItem[] | undefined;
  miIcon = AppIcons;
  notificationInterval;
  userFullName: string;
  message: Message[] = [];
  profileUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
  changePasswordPopup = false;
  editProfilePopup = false;
  subscription: Subscription;
  myDate = new Date(1496314866000);
  myDateLong = 1496314866000;
  minuts = 240;
  notifications: any = [];
  unReadNotificatonsCount = 0;
  limit = 5;
  offset = 0;
  showMoreOff = true;
  secondsCounter: any;
  logoutSession: any;

  constructor(
    private uiService: UiService,
    private userCommandService: UserCommonService,
    private router: Router,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.items = [
      {
        label: 'Logout',
        icon: 'fa fa-sign-out',
        command: event => {
          this.doSignOut();
        }
      }
    ];

    this.items = [
      {
          icon: 'pi pi-spin pi-cog'
      },
      {
          label: '',
          icon: 'pi pi-bell',
      },
      {
        label: '',
        icon: ''
    }
  ]
  
  const userObj: any = this.storageService.getItem(AppSettings.USER_DETAILS);
  if (userObj !== undefined && userObj !== null) {
    this.userFullName = userObj.firstName + ' ' + userObj.lastName;
    if (
      userObj.userDetails !== undefined &&
      userObj.userDetails !== null &&
      userObj.userDetails.profileImage !== null
    ) {
      this.profileUrl =
        AppSettings.GET_FILE_THUMB_URL + userObj.userDetails.profileImage;
    }
  }
}

onProfileClick(event: any) {
  event.preventDefault();
  const toggleClass = 'toggle-menu';
  event.currentTarget.classList.toggle(toggleClass);
}

onSideMenuClick(event: any) {
  event.preventDefault();
  document.getElementsByTagName('body')[0].classList.toggle('closed');
}

doSignOut() {

  const message = this.commonService.getLabel('lbl_confirm_idle_session');
  this.confirmationService.confirm({
    message,
    rejectVisible: false,
    accept: () => {
      this.refreshSession();
    },
    reject: () => {
      this.refreshSession();
    }
  });


  this.confirmationService.confirm({
    header: this.commonService.getLabel('header_logout_confirmation'),
    message: this.commonService.getLabel('msg_logout'),
    accept: () => {
      this.logout();
    }
  });


  this.confirmationService.confirm({
    target: event.target as EventTarget,
    message: this.commonService.getLabel('msg_logout'),
    header: this.commonService.getLabel('header_logout_confirmation'),
    icon: '',
    acceptButtonStyleClass: "p-button-danger",
    rejectButtonStyleClass: "p-button-text p-button-text",
    acceptIcon: "none",
    rejectIcon: "none",

    accept: () => {
      this.logout();
    }
  });
}

logout() {
  // Actual logic to perform a confirmation
  this.userCommandService.signOut().subscribe(results => {
    this.storageService.removeAll();
    this.router.navigate(['/auth/login']);
    location.reload();
  }, (error) => {
    this.errorMessage(error);
  });
}

onCancelEditProfile(event: any) {
  this.editProfilePopup = !this.editProfilePopup;
}

onCancelChangePassword(event: any) {
  this.changePasswordPopup = !this.changePasswordPopup;
}

errorMessage(error) {
  error = JSON.parse(error);
  this.message = [];
  this.message.push({
    severity: 'error',
    summary: 'error',
    detail: error.general[0].message
  });
}

setSessionInterval() {
  const sessionInterval = timer(10000, 1000);
  this.secondsCounter = sessionInterval.subscribe(n => {
    const idleSessionTime = <number>this.storageService.getItem(AppSettings.INTERVAL);
    if (idleSessionTime) {
      if (idleSessionTime < new Date().getTime()) {
        this.confirmYouThere();
        this.secondsCounter.unsubscribe();
      } else {
        clearTimeout(this.logoutSession);
      }
    }
  });
}

confirmYouThere() {
  this.sessionTimeout();
  const message = this.commonService.getLabel('lbl_confirm_idle_session');
  this.confirmationService.confirm({
    message,
    rejectVisible: false,
    accept: () => {
      this.refreshSession();
    },
    reject: () => {
      this.refreshSession();
    }
  });
}

sessionTimeout() {
  this.logoutSession = setTimeout(() => {
    const idleSessionTime = <number>this.storageService.getItem(AppSettings.INTERVAL);
    if (idleSessionTime < new Date().getTime()) {
      this.logout();
    } else {
      clearTimeout(this.logoutSession);
    }
  }, AppSettings.IDLE_TIME_ON_CONFIRM_MSG);
}

refreshSession() {
  const idleTime = moment()
    .add(AppSettings.IDLE_TIME, 'm')
    .valueOf();
  this.storageService.setItem('interval', idleTime);
  clearTimeout(this.logoutSession);
  this.setSessionInterval();
}


}


