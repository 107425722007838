import { CommonModule } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ConfirmationService, MenuItem } from "primeng/api";
import { AvatarModule } from "primeng/avatar";
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";
import { InputTextModule } from "primeng/inputtext";
import { MenubarModule } from "primeng/menubar";
import { PaginatorModule } from "primeng/paginator";
import { RippleModule } from "primeng/ripple";
import { SliderModule } from "primeng/slider";
import { MiGridComponent } from "../../shared/components/mi-grid/mi-grid.component";
import { CommonBindingDataService } from "../../shared/services/common-binding-data.service";
import { UiService } from "../../shared/services/ui.service";

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: "app-components",
  standalone: true,
  imports: [
    RippleModule,
    CommonModule,
    InputTextModule,
    AvatarModule,
    BadgeModule,
    MenubarModule,
    ButtonModule,
    MiGridComponent,
    PaginatorModule,
    SliderModule,
    FormsModule,
    DividerModule,
  ],
  templateUrl: "./customers.component.html",
  styleUrl: "./customers.component.scss",
})
export class CustomersComponent {
  first1: number;
  rows1: number;
  first2: number;
  rows2: number;
  first3: number;
  rows3: number;
  totalRecords: number;

  options = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 120, value: 120 },
  ];

  items: MenuItem[] | undefined;
  columns: any = [];
  buttons: any[] = [];
  url = "";
  exportUrlAlerts: string;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;

  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private commonService: CommonBindingDataService,
    private uiService: UiService
  ) {}
  ngOnInit() {
    this.items = [
      {
        label: this.commonBindingDataService.getLabel("lbl_tier"),
        icon: "",
        items: [
          {
            label: this.commonBindingDataService.getLabel("lbl_components"),
            icon: "pi pi-bolt",
          },
          {
            label: this.commonBindingDataService.getLabel("lbl_blocks"),
            icon: "pi pi-server",
          },
          {
            label: this.commonBindingDataService.getLabel("lbl_kits"),
            icon: "pi pi-pencil",
          },
          {
            label: this.commonBindingDataService.getLabel("lbl_templates"),
            icon: "pi pi-palette",
            items: [
              {
                label: this.commonBindingDataService.getLabel("lbl_apollo"),
                icon: "pi pi-palette",
              },
            ],
          },
        ],
      },
      {
        label: this.commonBindingDataService.getLabel("lbl_points"),
        icon: "",
        items: [
          {
            label: this.commonBindingDataService.getLabel("lbl_components"),
            icon: "pi pi-bolt",
          },
        ],
      },
      {
        label: this.commonBindingDataService.getLabel("lbl_date"),
        icon: "",
        items: [
          {
            label: this.commonBindingDataService.getLabel("lbl_components"),
            icon: "pi pi-bolt",
          },
        ],
      },
      {
        label: this.commonBindingDataService.getLabel("lbl_filters"),
        icon: "pi pi-sort-amount-down",
      },
    ];
  }

  getGridColumn() {
    this.columns.push({
      field: "name",
      sortId: "Name",
      header: this.commonBindingDataService.getLabel("lbl_name"),
      sort: true,
      resize: true,
      cellRendererHtml: (i) => this.colFullName(i),
    });
    this.columns.push({
      field: "email",
      sortId: "email",
      header: this.commonBindingDataService.getLabel("lbl_email"),
      sort: true,
      resize: true,
    });
    this.columns.push({
      field: "VIP",
      sortId: "VIP",
      header: this.commonBindingDataService.getLabel("lbl_VIP"),
      sort: true,
      resize: true,
    });
    this.columns.push({
      field: "Total Points",
      sortId: "totalPoints",
      header: this.commonBindingDataService.getLabel("lbl_total_points"),
      sort: true,
      resize: true,
    });
    this.columns.push({
      field: "Used Points",
      sortId: "usedPoints",
      header: this.commonBindingDataService.getLabel("lbl_used_points"),
      sort: true,
      resize: true,
    });
    this.columns.push({
      field: "status",
      sortId: "status",
      header: this.commonBindingDataService.getLabel("lbl_status"),
      sort: true,
      resize: true,
    });
    return this.columns;
  }
  colFullName(params) {
    const eDiv = document.createElement("div");
    eDiv.innerHTML = `${params.name}`;
    return eDiv;
  }

  updateUserStatus(id: any, activated: any) {
    if (id != null) {
      const data = {
        activated: activated,
      };
    }
  }
}
