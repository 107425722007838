import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppSettings {
  public static BASE_URL = environment.basePath;
  public static TENANT_CODE = environment.tenantCode;
  public static COUNTRY = "country";
  public static LANGUAGE = "language";
  public static HMAC_ENC_SIGNATURE = "HMAC-SHA256 Signature=";
  public static HEADER_WEB = "WEB";
  public static MAGNETIC_HOLDER = environment.magneticHolder;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  public static BODY_TYPE_XL = "XL";
  public static BODY_TYPE_L = "L";
  public static PASSWORD_PATTERN = "^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~!**^!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,40}$";
  public static BODY_TYPE_ESi = "ESi";
  public static BODY_TYPE_XLE = "XLE";
  public static VEHICLE_STATUS = {
    active: "Active",
    inShop: "InShop",
    outOfService: "",
  };
  public static USER_PREFERENCE = {
    COLUMN: "GRID_COLUMN_SEQUENCE",
    FILTER: "TABLE_FILTER",
  };

  public static VEHICLE_TAB_lIST = {
    ASSIGNED: "Assigned",
    UNASSIGNED: "Unassigned",
    DELETE: "Delete",
  };
  // public static IDLE_TIME = 60000;
  // public static IDLE_TIME_ON_CONFIRM_MSG = 60000;
  public static VIEW_CODE = {
    DEFAULT_TABLE_VIEW: "DEFAULT_TABLE_VIEW",
    DEFAULT_FILTER_VIEW: "DEFAULT_FILTER_VIEW",
    ADD_EDIT_VIEW: "ADD_EDIT_VIEW",
    DETAIL_VIEW: "DETAIL_VIEW",
    BULK_UPDATE_VIEW: "BULK_UPDATE_VIEW",
    EXCEL_IMPORT_VIEW: "EXCEL_IMPORT_VIEW",
  };
  public static IDLE_TIME = 8;
  public static IDLE_TIME_ON_CONFIRM_MSG = 120000;
  public static INTERVAL = "interval";
  public static HEADER_CONTENT_TYPE = "application/json";
  public static HEADER_CONTENT_TYPE_MULTIPART = "application/json";
  public static HEADER_AUTHORIZATION = "Authorization";
  public static PAGINATION_ROWS_PER_PAGE_LIMIT = 10;
  public static HEADER_TIMEZONE = "Timezone";
  public static HEADER_TIMEZONE_VALUE = "multipart/form-data";
  public static HEADER_ACCEPT_LANGUAGE = "en_IN"; //en_US
  public static HEADER_COUNTRY_CODE = "IN";
  public static HEADER_AUTHORIZATION_VALUE = "";
  public static USER: any = null;
  public static TOKEN_KEY = "Token";
  public static USER_DETAILS = "userDetails";
  public static FILE_UPLOAD_ID = "";
  public static FILE_UPLOAD_NAME = "";
  public static PROFILE_UPLOAD_ID = "";
  public static PROFILE_UPLOAD_NAME = "";
  public static FAQ: any = null;
  public static ACCESS_LIST = "ACCESS_LIST";
  public static ACCESS_MENU = "ACCESS_MENU";
  public static TIME_SETTINGS = "TIME_SETTINGS";
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static UPLOAD_FILE_URL = environment.basePath + "/secure/file";
  public static GET_FILE_URL = environment.basePath + "/file";
  public static GET_FILE_THUMB_URL = environment.basePath + "/file/thumbnail_";
  public static EXPORT_URL = environment.basePath + "/";
  public static PRINT_URL = environment.basePath + "/";
  public static DEFAULT_PROFILE_IMAGE_URL = "/assets/images/default_profile.png";
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static NOTIFICATION_INTERVAL: 600;
  public static IGNORE_LINK = [];
  public static SUPPORTED_COUNTRY: "india";
  public static WEBSITE_PATTERN = new RegExp(
    ["^(http://www.|https://www.|http://|https://)?", "[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})", "?(/.*)?$"].join("")
  );
  public static NAME_PATTERN = new RegExp(["^[A-Za-z\\d-_\\s]*$"].join(""));
  public static ROLE_PATTERN = new RegExp(["^[A-Za-z\\d-_\\s/\\\\]*$"].join(""));
  public static PHONE_PATTERN: any = "^[0-9][0-9]*$";
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static AMOUNT_PATTERN = new RegExp(["^[0-9\\d.]*$"].join(""));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = new RegExp(["[a-z|A-Z|0-9]+[@]+[a-z|A-Z|0-9]+[.]+[a-z|A-Z|0-9]+"].join(""));
  // public static EMAIL_PATTERN = new RegExp(['/^\S+@\S+\.\S+$/'].join(''));
  public static ZIPCODE_PATTERN = new RegExp(["^[0-9]"].join(""));
  public static DIGIT_PATTERN = new RegExp(["^[\\d]*$"].join(""));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(["^[1-9][0-9]*$"].join(""));
  public static FEE_PATTERN = new RegExp(["^\\d+(.\\d{1,2})?$"].join(""));
  public static ALPHA_NUMERIC = new RegExp(["^[A-Za-z0-9]"].join(""));
  public static VALUE_ONE_TO_HUNDRED = new RegExp(["^[1-9][0-9]?$|^100$"].join(""));
  public static NON_ZERO_VALUES = new RegExp(["^[1-9][0-9]*$"].join(""));
  public static HOTEL_PROFILE_UPLOAD_ID = "";
  public static HOTEL_PROFILE_UPLOAD_NAME = "";
  public static PERSON_NAME_PATTERN = "^[a-zA-Z][a-zA-Z\\s-_]+$";
  public static COMPANY_AND_PERSON_NAME_PATTERN = "^[a-zA-Z0-9][a-zA-Z0-9\\s-_]+$";
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static DAYS_COLLECTION = [
    { label: "Sun", value: "1" },
    { label: "Mon", value: "2" },
    { label: "Tue", value: "3" },
    { label: "Wed", value: "4" },
    { label: "Thu", value: "5" },
    { label: "Fri", value: "6" },
    { label: "Sat", value: "7" },
  ];

  public static COUNTRY_CODE = [
    { label: "Please Select", value: "" },
    { label: "+1", value: "+1" },
    { label: "+7", value: "+7" },
    { label: "+20", value: "+20" },
    { label: "+27", value: "+27" },
    { label: "+30", value: "+30" },
    { label: "+31", value: "+31" },
    { label: "+32", value: "+32" },
    { label: "+33", value: "+33" },
    { label: "+34", value: "+34" },
    { label: "+36", value: "+36" },
    { label: "+39", value: "+39" },
    { label: "+40", value: "+40" },
    { label: "+41", value: "+41" },
    { label: "+43", value: "+43" },
    { label: "+44", value: "+44" },
    { label: "+45", value: "+45" },
    { label: "+46", value: "+46" },
    { label: "+47", value: "+47" },
    { label: "+48", value: "+48" },
    { label: "+49", value: "+49" },
    { label: "+51", value: "+51" },
    { label: "+52", value: "+52" },
    { label: "+53", value: "+53" },
    { label: "+54", value: "+54" },
    { label: "+55", value: "+55" },
    { label: "+56", value: "+56" },
    { label: "+57", value: "+57" },
    { label: "+58", value: "+58" },
    { label: "+60", value: "+60" },
    { label: "+61", value: "+61" },
    { label: "+62", value: "+62" },
    { label: "+63", value: "+63" },
    { label: "+64", value: "+64" },
    { label: "+65", value: "+65" },
    { label: "+66", value: "+66" },
    { label: "+81", value: "+81" },
    { label: "+82", value: "+82" },
    { label: "+84", value: "+84" },
    { label: "+86", value: "+86" },
    { label: "+90", value: "+90" },
    { label: "+91", value: "+91" },
    { label: "+92", value: "+92" },
    { label: "+93", value: "+93" },
    { label: "+94", value: "+94" },
    { label: "+95", value: "+95" },
    { label: "+98", value: "+98" },
    { label: "+212", value: "+212" },
    { label: "+213", value: "+213" },
    { label: "+216", value: "+216" },
    { label: "+218", value: "+218" },
    { label: "+220", value: "+220" },
    { label: "+221", value: "+221" },
    { label: "+222", value: "+222" },
    { label: "+223", value: "+223" },
    { label: "+224", value: "+224" },
    { label: "+225", value: "+225" },
    { label: "+226", value: "+226" },
    { label: "+227", value: "+227" },
    { label: "+228", value: "+228" },
    { label: "+229", value: "+229" },
    { label: "+230", value: "+230" },
    { label: "+231", value: "+231" },
    { label: "+232", value: "+232" },
    { label: "+233", value: "+233" },
    { label: "+234", value: "+234" },
    { label: "+235", value: "+235" },
    { label: "+236", value: "+236" },
    { label: "+237", value: "+237" },
    { label: "+238", value: "+238" },
    { label: "+239", value: "+239" },
    { label: "+240", value: "+240" },
    { label: "+241", value: "+241" },
    { label: "+242", value: "+242" },
    { label: "+243", value: "+243" },
    { label: "+244", value: "+244" },
    { label: "+245", value: "+245" },
    { label: "+248", value: "+248" },
    { label: "+249", value: "+249" },
    { label: "+250", value: "+250" },
    { label: "+251", value: "+251" },
    { label: "+252", value: "+252" },
    { label: "+253", value: "+253" },
    { label: "+254", value: "+254" },
    { label: "+255", value: "+255" },
    { label: "+256", value: "+256" },
    { label: "+257", value: "+257" },
    { label: "+258", value: "+258" },
    { label: "+260", value: "+260" },
    { label: "+261", value: "+261" },
    { label: "+262", value: "+262" },
    { label: "+263", value: "+263" },
    { label: "+264", value: "+264" },
    { label: "+265", value: "+265" },
    { label: "+266", value: "+266" },
    { label: "+267", value: "+267" },
    { label: "+268", value: "+268" },
    { label: "+269", value: "+269" },
    { label: "+290", value: "+290" },
    { label: "+291", value: "+291" },
    { label: "+297", value: "+297" },
    { label: "+298", value: "+298" },
    { label: "+299", value: "+299" },
    { label: "+350", value: "+350" },
    { label: "+351", value: "+351" },
    { label: "+352", value: "+352" },
    { label: "+353", value: "+353" },
    { label: "+355", value: "+355" },
    { label: "+356", value: "+356" },
    { label: "+357", value: "+357" },
    { label: "+358", value: "+358" },
    { label: "+359", value: "+359" },
    { label: "+370", value: "+370" },
    { label: "+371", value: "+371" },
    { label: "+372", value: "+372" },
    { label: "+373", value: "+373" },
    { label: "+374", value: "+374" },
    { label: "+375", value: "+375" },
    { label: "+376", value: "+376" },
    { label: "+377", value: "+377" },
    { label: "+378", value: "+378" },
    { label: "+380", value: "+380" },
    { label: "+381", value: "+381" },
    { label: "+382", value: "+382" },
    { label: "+385", value: "+385" },
    { label: "+386", value: "+386" },
    { label: "+387", value: "+387" },
    { label: "+389", value: "+389" },
    { label: "+420", value: "+420" },
    { label: "+421", value: "+421" },
    { label: "+423", value: "+423" },
    { label: "+500", value: "+500" },
    { label: "+501", value: "+501" },
    { label: "+502", value: "+502" },
    { label: "+503", value: "+503" },
    { label: "+504", value: "+504" },
    { label: "+505", value: "+505" },
    { label: "+506", value: "+506" },
    { label: "+507", value: "+507" },
    { label: "+508", value: "+508" },
    { label: "+509", value: "+509" },
    { label: "+590", value: "+590" },
    { label: "+591", value: "+591" },
    { label: "+592", value: "+592" },
    { label: "+593", value: "+593" },
    { label: "+595", value: "+595" },
    { label: "+597", value: "+597" },
    { label: "+598", value: "+598" },
    { label: "+670", value: "+670" },
    { label: "+672", value: "+672" },
    { label: "+673", value: "+673" },
    { label: "+674", value: "+674" },
    { label: "+675", value: "+675" },
    { label: "+676", value: "+676" },
    { label: "+677", value: "+677" },
    { label: "+678", value: "+678" },
    { label: "+679", value: "+679" },
    { label: "+680", value: "+680" },
    { label: "+681", value: "+681" },
    { label: "+682", value: "+682" },
    { label: "+683", value: "+683" },
    { label: "+685", value: "+685" },
    { label: "+686", value: "+686" },
    { label: "+687", value: "+687" },
    { label: "+688", value: "+688" },
    { label: "+689", value: "+689" },
    { label: "+690", value: "+690" },
    { label: "+691", value: "+691" },
    { label: "+692", value: "+692" },
    { label: "+850", value: "+850" },
    { label: "+852", value: "+852" },
    { label: "+853", value: "+853" },
    { label: "+855", value: "+855" },
    { label: "+856", value: "+856" },
    { label: "+870", value: "+870" },
    { label: "+880", value: "+880" },
    { label: "+886", value: "+886" },
    { label: "+960", value: "+960" },
    { label: "+961", value: "+961" },
    { label: "+962", value: "+962" },
    { label: "+963", value: "+963" },
    { label: "+964", value: "+964" },
    { label: "+965", value: "+965" },
    { label: "+966", value: "+966" },
    { label: "+967", value: "+967" },
    { label: "+968", value: "+968" },
    { label: "+971", value: "+971" },
    { label: "+972", value: "+972" },
    { label: "+973", value: "+973" },
    { label: "+974", value: "+974" },
    { label: "+975", value: "+975" },
    { label: "+976", value: "+976" },
    { label: "+977", value: "+977" },
    { label: "+992", value: "+992" },
    { label: "+993", value: "+993" },
    { label: "+994", value: "+994" },
    { label: "+995", value: "+995" },
    { label: "+996", value: "+996" },
    { label: "+998", value: "+998" },
  ];

  public static LOGGED_IN_ROLE = "";
  public static roles = [
    {
      roleCode: "System Admin",
      redirect: "/admin-dashboard",
    },
  ];

  public static DATE_FORMATTER = "MMM DD, YYYY hh:mm A";

  public static COOKIE_EXPIRY = 1 * 24 * 60 * 60 * 1000;

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static SESSION_KEY_NAME = "X-SESSION-KEY";
  public MESSAGE_COLOR_STATUS = {
    active: "Active",
    away: "Away",
    onCall: "On Call",
    offline: "Offline",
  };

  public static ENTITY_TYPE = {
    VEHICLE: "vehicle",
  };

  public COMMUNICATION_DROPDOWN = [
    { label: "Individual", value: "individual" },
    { label: "Group", value: "group" },
  ];

  public static REPLACE_WITH_DROPDOWN = [
    { label: "Replace With", value: "replaceWith", disabled: false },
    { label: "Remove all values", value: "removeAllValues", disabled: false },
  ];

  public static MENU_ITEMS = [
    { label: "Vehicles", value: "vehicles" },
    { label: "Passengers", value: "passengers" },
  ];

  public static magneticHolder() {
    return AppSettings.MAGNETIC_HOLDER;
  }

  public static HEADER_UPLOAD_CONTENT_TYPE = "multipart/form-data";
  public static DOCUMENTS_TYPE = {
    PROFILE: "profile",
    DOCUMENT: "document",
    REPORT: "report",
    KML: "kml",
  };
}
