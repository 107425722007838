import { Component, OnInit } from '@angular/core';

import { AppIcons } from '../../../shared/app.icons';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-mi-icons',
  templateUrl: './mi-icons.component.html',
  styleUrls: ['./mi-icons.component.scss'],
  standalone: true,
  imports: [KeyValuePipe]
})
export class MiIconsComponent implements OnInit {
  miIcons = AppIcons;

  ngOnInit(): void {
  }

}
