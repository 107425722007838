<div #dataTable class="p-grid" (window:resize)="onResize($event)" [class]="divClass">
  <div class="search-and-export-data-panel p-grid">
    @if (searchBar == "top") {
      <div class="p-col-12 p-lg-6">
        <input
          type="text"
          class="grid-search-bar top"
          [(ngModel)]="search"
          (keyup)="onQuickFilterChanged($event)"
          placeholder="Press enter to search"
        />
        <label for="search-bar" class="fa fa-search input-icon"></label>
      </div>
    }
    @if (exportButton == "top") {
      <div class="export-button p-col-12 p-lg-6">
        <span class="header-action-btn export" title="Download Excel">
          Export <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
        </span>
      </div>
    }
  </div>

  <p-table
    [lazy]="true"
    [rows]="paginationPageSize"
    [value]="datasource"
    [paginator]="pagination"
    [totalRecords]="totalRecords"
    (onLazyLoad)="loadDatasourceLazy($event)"
    [selectionMode]="rowSelection"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowUnselect($event)"
    [(selection)]="selectedRecords"
    class="p-col-12"
  >
    <ng-template pTemplate="header">
      <tr>
        @for (col of columnDefs; track col) {
          <th>
            {{ col.header }}
            @if (col.sort) {
              <p-sortIcon [field]="col.field"></p-sortIcon>
            }
          </th>
          @if (!col.resize) {
            <th>
              {{ col.header }}
              @if (col.sort) {
                <p-sortIcon [field]="col.field"></p-sortIcon>
              }
            </th>
          }
        }
        @if (actionButtonsDefs.length) {
          <th style="width: 50px"></th>
        }
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr class="" [attr.colspan]="columnDefs.length">
        <td>
          @if (totalRecords == 0) {
            <div class="no-records">
              <div class="center-div" translate>lbl_no_records_found</div>
            </div>
          }
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex % 2 == 0 ? 'even-row' : 'odd-row'">
        @for (col of columnDefs; track col) {
          <td [class]="col.class">
            <!-- {{rowData[col.field]}} -->
            <div innerHTML="{{ rowData[col.field] }}"></div>
          </td>
        }

        @if (actionButtonsDefs.length) {
          <td class="action-icons" (click)="actionBtnClick($event, rowData)">
            <div class="action-icon">
              <div class="grid-menu" appClickOutside (clickOutside)="close($event)">
                @for (btn of actionButtonsDefs; track btn) {
                  <div class="grid-menu-item">
                    <div [class]="btn.class">{{ btn.label }}</div>
                  </div>
                }
              </div>
            </div>
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
</div>
