export const AppIcons = {
  Home: 'pi pi-home',
  HOME: 'mi-lg mi-home',
  BOOKING: 'mi-lg mi-booking',
  book: 'pi pi-chevron-circle-up',
  reward: 'pi pi-star',
  customers: 'pi pi-user',
  configration: 'pi pi-cog',
  bell: 'pi pi-bell',
  guide: 'pi pi-book',
  DISPATCH: 'mi-lg mi-dispatch',
  PASSENGER: 'mi-lg mi-passenger',
  VEHICLE: 'mi-lg mi-vehicle',
  DRIVER: 'mi-lg mi-driver',
  SERVICE: 'mi-lg mi-service',
  REMINDER: 'mi-lg mi-reminder',
  EXPENSE_HISTORY: 'mi-lg mi-expense-history',
  EQUIPMENT: 'mi-lg mi-equipment',
  VEHICLE_INSPECTION: 'mi-lg mi-vehicle-inspection',
  ISSUES: 'mi-lg mi-issues',
  DOCUMENT: 'mi-lg mi-document',
  FUEL_HISTORY: 'mi-lg mi-fuel-history',
  VENDORS: 'mi-lg mi-vendors',
  SHIFT_MANAGEMENT: 'mi-lg mi-shift-management',
  REPORTS: 'mi-lg mi-reports',
  TRAINING: 'mi-lg mi-training',
  KNOWLEDGE: 'mi-lg mi-knowledge',
  USER_MANAGEMENT: 'mi-lg mi-user-management',
  MI_MAP: 'mi-lg mi-map',
  MI_PRINT: 'mi-lg mi-print',
  SETTINGS_FUTURE: "mi-lg mi-settings_future",
  SHARE: "mi-lg mi-share",
  SHARE_OUTLINE: "mi-lg mi-share_outline",
  SLIDER_01: "mi-lg mi-slider_01",
  SLIDER_02: "mi-lg mi-slider_02",
  SLIDER_03: "mi-lg mi-slider_03",
  STOPWATCH: "mi-lg mi-stopwatch",
  SUBTRACT: "mi-lg mi-subtract",
  SUN: "mi-lg mi-sun",
  TRASH_FULL: "mi-lg mi-trash_full",
  UNLINK: "mi-lg mi-unlink",
  ADD: "mi-lg mi-add",
  ALARM: "mi-lg mi-alarm",
  ALARM_ADD: "mi-lg mi-alarm_add",
  ARCHIVE_3: "mi-lg mi-archive_3",
  ARCHIVE_4: "mi-lg mi-archive_4",
  ATTACH: "mi-lg mi-attach",
  BULB: "mi-lg mi-bulb",
  CHECKBOX: "mi-lg mi-checkbox",
  CHECKBOX_CHECKED_2: "mi-lg mi-checkbox_checked_2",
  CHECKBOX_CHECKED: "mi-lg mi-checkbox_checked",
  CHECKBOX_SQUARE: "mi-lg mi-checkbox_square",
  CIRCLE_CHECK: "mi-lg mi-circle_check",
  CIRCLE_CHECK_OUTLINE: "mi-lg mi-circle_check_outline",
  CIRCLE_CHECKED: "mi-lg mi-circle_checked",
  CLOCK: "mi-lg mi-clock",
  COOKIE: "mi-lg mi-cookie",
  COMMAND: "mi-lg mi-command",
  CONFUSED: "mi-lg mi-confused",
  CREDIT_CARD: "mi-lg mi-credit_card",
  CREDIT_CARD_ALT: "mi-lg mi-credit_card_alt",
  DONE: "mi-lg mi-done",
  DONE_ALL: "mi-lg mi-done_all",
  DOWNLOAD: "mi-lg mi-download",
  DOWNLOAD_DONE: "mi-lg mi-download_done",
  EDIT_3: "mi-lg mi-edit_3",
  ELLIPSE: "mi-lg mi-ellipse",
  EXIT: "mi-lg mi-exit",
  EXTERNAL_LINK: "mi-lg mi-external_link",
  FILTER_2: "mi-lg mi-filter_2",
  FILTER_SELECTED: "mi-lg mi-filter_selected",
  HAPPY: "mi-lg mi-happy",
  HEART_FILL: "mi-lg mi-heart_fill",
  HEART_OUTLINE: "mi-lg mi-heart_outline",
  HELP_CIRCLE: "mi-lg mi-help_circle",
  HELP_CIRCLE_OUTLINE: "mi-lg mi-help_circle_outline",
  HELP_QUESTION_MARK: "mi-lg mi-help_question_mark",
  IMAGE_ALT: "mi-lg mi-image_alt",
  LABEL: "mi-lg mi-label",
  LAYERS: "mi-lg mi-layers",
  LAYERS_ALT: "mi-lg mi-layers_alt",
  LINK: "mi-lg mi-link",
  LINK_02: "mi-lg mi-link_02",
  LOADING: "mi-lg mi-loading",
  LOCATE_ME: "mi-lg mi-locate_me",
  PASSWORD: "mi-lg mi-password",
  LOCATION: "mi-lg mi-location",
  LOCATION_OUTLINE: "mi-lg mi-location_outline",
  LOCATION_OUTLINE_BLACK: "mi-lg mi-location_outline_black",
  LOCATION_OUTLINE_RED: "mi-lg mi-location_outline_red",
  LOG_OUT: "mi-lg mi-log_out",
  MAIL: "mi-lg mi-mail",
  MAIL_OPEN: "mi-lg mi-mail_open",
  MAP_2: "mi-lg mi-map 2",
  MAP: "mi-lg mi-map",
  MOON: "mi-lg mi-moon",
  OFF_CLOSE: "mi-lg mi-off_close",
  OFF_OUTLINE_CLOSE: "mi-lg mi-off_outline_close",
  PATH: "mi-lg mi-path",
  PHONE: "mi-lg mi-phone",
  RADIO_FILLED: "mi-lg mi-radio_filled",
  RADIO: "mi-lg mi-radio",
  RADIO_FILLED_2: "mi-lg mi-radio_filled",
  REFRESH: "mi-lg mi-refresh",
  REFRESH_02: "mi-lg mi-refresh_02",
  SAD: "mi-lg mi-sad",
  SETTINGS: "mi-lg mi-settings",
  SETTINGS_FILLED: "mi-lg mi-settings_filled",
  NO_PRIORITY: "mi-lg mi-no_priority",
  CRITICAL: "mi-lg critical",
  HIGH: "mi-lg high",
  LOW: "mi-lg low",
  MEDIUM: "mi-lg medium",
  THIN_BIG_DOWN: "mi-lg thin-big-down",
  THIN_BIG_LEFT: "mi-lg thin-big-left",
  THIN_BIG_RIGHT: "mi-lg thin-big-right",
  THIN_BIG_UP: "mi-lg thin-big-up",
  THIN_LONG_02_DOWN: "mi-lg thin-long-02-down",
  THIN_LONG_02_LEFT: "mi-lg thin-long-02-left",
  THIN_LONG_02_RIGHT: "mi-lg thin-long-02-right",
  THIN_LONG_02_UP: "mi-lg thin-long-02-up",
  THIN_LONG_DOWN: "mi-lg thin-long-down",
  THIN_LONG_LEFT: "mi-lg thin-long-left",
  THIN_LONG_RIGHT: "mi-lg thin-long-right",
  THIN_LONG_UP: "mi-lg thin-long-up",
  UNFOLD_LESS: "mi-lg unfold-less",
  UNFOLD_MORE: "mi-lg unfold-more",
  CARET_DOWN_2: "mi-lg caret-down-2",
  CARET_DOWN: "mi-lg caret-down",
  CARET_LEFT: "mi-lg caret-left",
  CARET_RIGHT_2: "mi-lg caret-right-2",
  CARET_RIGHT: "mi-lg caret-right",
  CARET_UP: "mi-lg caret-up",
  CHEVRON_BIG_DOWN: "mi-lg chevron-big-down",
  CHEVRON_BIG_LEFT: "mi-lg chevron-big-left",
  CHEVRON_BIG_RIGHT: "mi-lg chevron-big-right",
  CHEVRON_BIG_UP: "mi-lg chevron-big-up",
  CHEVRON_DOWN: "mi-lg chevron-down",
  CHEVRON_DUO_DOWN: "mi-lg chevron-duo-down",
  CHEVRON_DUO_LEFT: "mi-lg chevron-duo-left",
  CHEVRON_DUO_RIGHT: "mi-lg chevron-duo-right",
  CHEVRON_DUO_UP: "mi-lg chevron-duo-up",
  CHEVRON_LEFT: "mi-lg chevron-left",
  CHEVRON_RIGHT: "mi-lg chevron-right",
  CHEVRON_UP: "mi-lg chevron-up",
  CIRCLE_CHEVRON_DOWN: "mi-lg circle-chevron-down",
  CIRCLE_CHEVRON_LEFT: "mi-lg circle-chevron-left",
  CIRCLE_CHEVRON_RIGHT: "mi-lg circle-chevron-right",
  CIRCLE_CHEVRON_UP: "mi-lg circle-chevron-up",
  CIRCLE_DOWN: "mi-lg circle-down",
  CIRCLE_LEFT_FILLED: "mi-lg circle-left-filled",
  CIRCLE_LEFT: "mi-lg circle-left",
  CIRCLE_RIGHT: "mi-lg circle-right",
  CIRCLE_UP: "mi-lg circle-up",
  EXPAND: "mi-lg expand",
  FIRST_PAGE: "mi-lg first-page",
  FORWARD: "mi-lg forward",
  LAST_PAGE: "mi-lg last-page",
  LONG_BOTTOM_DOWN: "mi-lg long-bottom-down",
  LONG_BOTTOM_UP: "mi-lg long-bottom-up",
  LONG_DOWN: "mi-lg long-down",
  LONG_LEFT: "mi-lg long-left",
  LONG_RIGHT: "mi-lg long-right",
  LONG_UP: "mi-lg long-up",
  LONG_UP_LEFT_: "mi-lg long-up-left-",
  LONG_UP_RIGHT: "mi-lg long-up-right",
  REPLY: "mi-lg reply",
  SENT: "mi-lg sent",
  SHORT_DOWN: "mi-lg short-down",
  SHORT_LEFT: "mi-lg short-left",
  SHORT_RIGHT: "mi-lg short-right",
  SHORT_UP: "mi-lg short-up",
  SHRINK: "mi-lg shrink",
  SMALL_LONG_DOWN: "mi-lg small-long-down",
  SMALL_LONG_LEFT: "mi-lg small-long-left",
  SMALL_LONG_RIGHT: "mi-lg small-long-right",
  SMALL_LONG_UP: "mi-lg small-long-up",
  SUB_LEFT: "mi-lg sub-left",
  SUB_RIGHT: "mi-lg sub-right",
  OVERDUE: "mi-lg overdue",
  WARNING: "mi-lg warning",
  WARNING_OUTLINE: "mi-lg warning_outline",
  ERROR: "mi-lg error",
  ERROR_OUTLINE: "mi-lg error_outline",
  INFO_CIRCLE: "mi-lg info_circle",
  INFO_CIRCLE_OUTLINE: "mi-lg info_circle_outline",
  INFO_SQUARE: "mi-lg info_square",
  INFO_SQUARE_OUTLINE: "mi-lg info_square_outline",
  CALENDAR_MINUS: "mi-lg calendar_minus",
  CALENDAR_PLUS: "mi-lg calendar_plus",
  CALENDAR_WEEK: "mi-lg calendar_week",
  CALENDAR_X: "mi-lg calendar_x",
  EXPIRED: "mi-lg expired",
  CALENDAR_DATE_AND_TIME: "mi-lg calendar date and time",
  CALENDAR: "mi-lg calendar",
  CALENDAR_CALENDAR: "mi-lg calendar_calendar",
  CALENDAR_CHECK: "mi-lg calendar_check",
  CALENDAR_EDIT: "mi-lg calendar_edit",
  CALENDAR_EVENT: "mi-lg calendar_event",
  GRID_ROUND: "mi-lg grid_round",
  GRID_SMALL: "mi-lg grid_small",
  GRID_SMALL_ROUND: "mi-lg grid_small_round",
  GRID_VERTICAL: "mi-lg grid_vertical",
  GRID_VERTICAL_ROUND: "mi-lg grid_vertical_round",
  COLUMNS: "mi-lg columns",
  DASHBOARD_02: "mi-lg dashboard_02",
  GRID_O: "mi-lg grid-o",
  GRID_BIG: "mi-lg grid_big",
  GRID_BIG_ROUND: "mi-lg grid_big_round",
  GRID_HORIZONTAL: "mi-lg grid_horizontal",
  GRID_HORIZONTAL_ROUND: "mi-lg grid_horizontal_round",
  MENU_ALT_03: "mi-lg menu_alt_03",
  MENU_ALT_04: "mi-lg menu_alt_04",
  MENU_ALT_05: "mi-lg menu_alt_05",
  MENU_DUO: "mi-lg menu_duo",
  MORE_HORIZONTAL: "mi-lg more_horizontal",
  MORE_VERTICAL: "mi-lg more_vertical",
  CLOSE_BIG: "mi-lg close_big",
  CLOSE_SMALL: "mi-lg close_small",
  HAMBURGER_1: "mi-lg hamburger_1",
  HAMBURGER_2: "mi-lg hamburger_2",
  MENU_ALT_01: "mi-lg menu_alt_01",
  MENU_ALT_02: "mi-lg menu_alt_02",
  NOTIFICATION_OUTLINE_DOT: "mi-lg notification_outline_dot",
  NOTIFICATION_OUTLINE_MINUS: "mi-lg notification_outline_minus",
  NOTIFICATION_OUTLINE_PLUS: "mi-lg notification_outline_plus",
  NOTIFICATION_PLUS: "mi-lg notification_plus",
  NOTIFICATION: "mi-lg notification",
  NOTIFICATION_ACTIVE: "mi-lg notification_active",
  NOTIFICATION_DEACTIVATED: "mi-lg notification_deactivated",
  NOTIFICATION_DOT: "mi-lg notification_dot",
  NOTIFICATION_MINUS: "mi-lg notification_minus",
  NOTIFICATION_OUTLINE: "mi-lg notification_outline",
  RECONFIRMED: "mi-lg reconfirmed",
  SMS: "mi-lg sms",
  USER_1: "mi-lg user_1",
  USER_ADD: "mi-lg user_add",
  USER_CHECK: "mi-lg user_check",
  USER_CIRCLE: "mi-lg user_circle",
  USER_MINUS: "mi-lg user_minus",
  USER_PIN: "mi-lg user_pin",
  USER_PLUS: "mi-lg user_plus",
  USER_VOICE: "mi-lg user_voice",
  USER_X: "mi-lg user_x",
  DRIVER_ARRIVED: "mi-lg driver_arrived",
  ID_CARD: "mi-lg id_card",
  IMPORT: "mi-lg import",
  ON_THE_WAY: "mi-lg on_the_way",
  ON_TRIP: "mi-lg on_trip",
  SAVE: "mi-lg mi-save",

  SETTINGS_OVERLAY: "mi-lg settings_overlay",
  SHIFT: "mi-lg shift",
  SNOOZE: "mi-lg snooze",
  STEERING_WHEEL: "mi-lg steering_wheel",
  ADD_LESSONS: "mi-lg add_lessons",
  ADD_LESSONS_1: "mi-lg add_lessons_1",

  ADD_TO_LIST: "mi-lg add_to_list",
  BASIC_TRAY: "mi-lg basic_tray",
  CALENDER_NEW: "mi-lg calender_new",
  DANGER_1: "mi-lg danger_1",
  DAY_OFF: "mi-lg day_off",
  EMPTY_GRID: "mi-lg empty_grid",
  GROUP_PIN: "mi-lg group_pin",
  IN_PROGRESS: "mi-lg in_progress",
  IN_PROGRESS_1: "mi-lg in_progress_1",
  NOTIFICATION_RED: "mi-xl notification-red",
  PUBLISH: "mi-lg publish",
  CAMERA: "mi-lg camera",
  VIDEO: "mi-lg video",
  PHONE_WHITE: "mi-lg phone-white",
  SEARCH: "mi-lg search",
  USER_GROUP: "mi-lg user-group",
  TEXT_ALIGN: "mi-lg text-align",
  PLUS: "mi-lg plus",
  REPEAT: "mi-lg repeat",
  ADD_MULTIPLE: 'mi-lg add-multiple',
  UPLOAD: 'mi-lg upload',
  DOWNLOAD2: 'mi-lg download',
  EXPORT: 'mi-lg export',
  ARCHIVED_SEARCH: 'mi-lg archived_search',
  DETAILS: 'mi-lg details',
  FINANCIAL: 'mi-lg financial',
  FEATURES: 'mi-lg features',
  SPECIFICATIONS: 'mi-lg specifications'
}