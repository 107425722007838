import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AppSettings } from '../../app.settings';
import { Language } from '../../models/language';
import { Country } from '../../models/country';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NgClass } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [LoaderComponent, ToastModule, NgClass, SidebarComponent, HeaderComponent, RouterOutlet]
})
export class LayoutComponent implements OnInit {
  isSidebarFull: boolean = false;
  metaData: Country;
  languageDetails: Language;
  constructor(private cs: CommonBindingDataService) { }

  ngOnInit() {

  }

}
