
    <div class=" text-center mb-7">
        <div class="flex justify-content-center mb-3">
            <div>
                <div class="text-blue-500 font-bold text-5xl">152</div>
            </div>
        
        </div>
        <span class=" font-medium">New Request </span>
    </div>

    <div class=" text-center mb-7">
        <div class="flex justify-content-center mb-3">
            <div>
                <div class="text-green-500 font-bold text-5xl">16</div>
            </div>
        
        </div>
        <span class=" font-medium">Live Journey </span>
    </div>


    <div class=" text-center mb-7">
        <div class="flex justify-content-center mb-3">
            <div>
                <div class="text-red-500 font-bold text-5xl">7</div>
            </div>
        
        </div>
        <span class=" font-medium">Critical</span>
    </div>


    <div class=" text-center mb-7">
        <div class="flex justify-content-center mb-3">
            <div>
                <div class=" font-bold text-5xl">12</div>
            </div>
        
        </div>
        <span class=" font-medium">Watch List</span>
    </div>
