import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
// import { Product } from '../../api/product';
// import { ProductService } from '../../service/product.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../../app.layout.service';
import { AppIcons } from '../../../shared/app.icons';
import { TimelineModule } from 'primeng/timeline';
import { NgStyle, NgClass, CommonModule } from '@angular/common';
import { BookingStatusCardComponent } from '../booking-status-card/booking-status-card.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [MenuModule, ButtonModule, TabMenuModule, BookingStatusCardComponent, NgStyle, TimelineModule, NgClass,CommonModule]
})
export class DashboardComponent implements OnInit, OnDestroy {
  
  events1: any[] = [];
  items!: MenuItem[];
  passengerByTypes: any[] = [];

  menuItems: MenuItem[] = [];
  routeItems: MenuItem[] = [];

  products!: [];

  chartData: any;

  chartOptions: any;

  subscription!: Subscription;

  constructor(public layoutService: LayoutService) {
    this.subscription = this.layoutService.configUpdate$.subscribe(() => {
      this.initChart();
    });
  }

  ngOnInit() {
    console.log("test");
    this.initChart();
    this.menuItems = [
      {
        label: 'Live Dashboard', icon: 'pi pi-fw pi-check'
      },
      {
        label: 'Vehicle', icon: 'pi pi-fw pi-refresh'
      },
      {
        label: 'Booking', icon: 'pi pi-fw pi-trash'
      },
      {
        separator: true
      },
      {
        label: 'Training', icon: AppIcons.BOOKING
      },
      {
        label: 'Communication', icon: AppIcons.BULB
      },
    ];
    this.routeItems = [
      { label: 'Map', routerLink: 'map', icon: AppIcons.MI_MAP },
      { label: 'Heat Map', routerLink: 'heat-map', icon: 'pi pi-fw pi-home' },
      { label: 'Activity Feed', routerLink: 'activity-feed', icon: 'pi pi-fw pi-home' },
    ];



    this.items = [
      { label: 'Hide', icon: 'pi pi-eye-slash' }
    ];

    this.passengerByTypes = [
      { label: 'PLayer', count: 35 },
      { label: 'Player Coach', count: 12 },
      { label: 'Player ', count: 12 },
      { label: 'Player ', count: 12 },
      { label: 'Player ', count: 13 },
      { label: 'Player ', count: 13 },
      { label: 'Player', count: 12 },
      { label: 'Player', count: 1 },
      { label: 'Player ', count: 12 },
    ];

    this.events1 = [
      { status: 'Ordered', date: '15/10/2020 10:30', icon: AppIcons.RADIO_FILLED, color: '#FFF' },
      { status: 'Processing', date: '15/10/2020 14:00', icon: AppIcons.RADIO_FILLED, color: '#FFF' },
      { status: 'Shipped', date: '15/10/2020 16:15', icon: AppIcons.RADIO_FILLED, color: '#FFF' },
      { status: 'Delivered', date: '16/10/2020 10:00', icon: AppIcons.RADIO_FILLED, color: '#FFF' }
    ];
  }

  initChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.chartData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          backgroundColor: documentStyle.getPropertyValue('--bluegray-700'),
          borderColor: documentStyle.getPropertyValue('--bluegray-700'),
          tension: .4
        },
        {
          label: 'Second Dataset',
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          backgroundColor: documentStyle.getPropertyValue('--green-600'),
          borderColor: documentStyle.getPropertyValue('--green-600'),
          tension: .4
        }
      ]
    };

    this.chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
